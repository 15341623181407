import React from "react";
import tw from "twin.macro";
import { graphql } from "gatsby";
import { PageLayout, LocationsCard, CovidWarning } from "../components";

const Locations = ({ data: { allStrapiBranchSettings } }) => {
  return (
    <PageLayout title="Our Locations" mainStyles={tw`bg-dsrTeal-50`}>
      <CovidWarning />
      <section tw="container mx-auto bg-dsrTeal-50">
        <div tw="space-y-12">
          {allStrapiBranchSettings.edges.map(({ node }) => (
            <LocationsCard key={node.name} {...node} />
          ))}
        </div>
      </section>
    </PageLayout>
  );
};

export default Locations;

export const query = graphql`
  query allGroupBranches {
    allStrapiBranchSettings(filter: { group: { name: { eq: "DSR" } } }) {
      edges {
        node {
          contactDetails {
            addressLocality
            email
            postalCode
            streetAddress
            telephone
          }
          description
          googlePlacesId
          name
          openingHours {
            closingHour
            day
            closingMinute
            openingHour
            openingMinute
            closed
          }
          openingHoursNotes
          secondaryName
          group {
            socialLinks {
              facebookUrl
              instagramUrl
              twitterUrl
            }
          }
        }
      }
    }
  }
`;
